<template>
  <div class="dashboard">
    <v-row justify="center">
      <v-col>
        <img style="min-width: 100%;" src="../assets/auth-header.svg">
      </v-col>
    </v-row>
    
    <v-row v-if="isAuthenticated">
      <div class="text-center">
        <p class="mb-2">Logged in as {{ user.full_name }} ({{ user.email }})</p>
        <small><a href="#" @click.prevent="logout">Is this not you?</a></small>
      </div>
    </v-row>

    <div class="pending" v-if="!isInProgress">
      <v-row>
        <v-col class="text-center">
          <h2>You're signed up!</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-chip style="width: 100%; text-align: center;" large label dark color="#7A9679">Challenge Begins in <span class="countdown">{{ daysLeft }} days</span></v-chip>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center">
          <h3>Now is the perfect time to challenge a friend to join you!</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p>Yoga is better with friends, right? Invite your friends to get more entries to win the grand prize.</p>
          <p>Share your unique link below with your friends. If they sign up using your link, you get more entries!*</p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12">
          <label class="referenceLabel">Your Link</label>
          <v-text-field hide-details :value="referenceUrl" outlined disabled />
        </v-col>
        <v-col cols="12" class="text-center mb-5">
          <v-btn @click="copyToClipboard" x-large depressed color="#C1563E" dark>Copy My Link</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <small>
            <p>*Receive an extra raffle entry per person who enrolls in the challenge using your custom link above AND takes at least 5 classes in March.</p>
            <p>**Bonus entry if the person enrolling has never been to Ra before!</p>
          </small>
        </v-col>
      </v-row>
    </div>

    <div class="in-progress" v-if="isInProgress">
      <v-row>
        <v-col>
          <div class="progress-container px-10 mt-3">
            <v-row>
              <v-col class="text-center">
                <h2>I've completed</h2>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="8">
                <v-progress-circular
                  :rotate="270"
                  :size="200"
                  :width="45"
                  :value="radialValue"
                  color="#EFA83C"
                >
                  <h1 class="class-count text-center">{{ classCount }}<br />
                  <span class="class-count-label">{{ (classCount === 1) ? 'class' : 'classes' }}</span></h1>
                </v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <h2>of my 30 day<br /> yoga challenge!</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="quote text-center">"Yoga does not just change the way we see things, it transforms the person who sees."</p>
                <p class="text-center">B.K.S Iyenger</p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center">
          <h2>Invite your friends to get more entries!</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p>Yoga is better with friends, right? Invite your friends to get more entries to win the grand prize.</p>
          <p>Share your unique link below with your friends. If they sign up using your link, you get more entries!*</p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12">
          <label class="referenceLabel">Your Link</label>
          <v-text-field hide-details :value="referenceUrl" outlined disabled />
        </v-col>
        <v-col cols="12" class="text-center mb-5">
          <v-btn @click="copyToClipboard" x-large depressed color="#C1563E" dark>Copy My Link</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="mb-15">
          <small>
            <p>*You get <strong>1 additional entry</strong> if an existing Ra Member on an unlimited membership sign up.</p>
            <p>You get <strong>2 additional entries</strong> if one of your friends signs up for a new unlimited membership or purchases our 30-Day March Yoga Challenge Plan</p>
          </small>
        </v-col>
      </v-row>
    </div>

    <v-row justify="center">
      <v-col>
        <img style="min-width: 100%;" src="../assets/two-things.svg">
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col class="mb-5">
        <img style="min-width: 100%;" src="../assets/complete.svg">
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="prize-container">
        <h2 class="prize-header"><img src="../assets/prize-icon.svg"> Grand Prize - $1,000 Value</h2>
        <ul class="prize-list">
          <li>3 Months of Unlimited Premium Membership</li>
          <li>15 Guest Credits</li>
          <li>Free Yoga Outfit</li>
        </ul>
      </v-col>
      <v-col cols="12" class="prize-container">
        <h2 class="prize-header"><img src="../assets/prize-icon.svg"> Second Prize - $750 Value</h2>
        <ul class="prize-list">
          <li>2 Months of Unlimited Premium Membership</li>
          <li>10 Guest Credits</li>
          <li>Free Yoga Bottom</li>
        </ul>
      </v-col>
      <v-col cols="12" class="prize-container">
        <h2 class="prize-header"><img src="../assets/prize-icon.svg"> Third Prize - $500 Value</h2>
        <ul class="prize-list">
          <li>1 Months of Unlimited Premium Membership</li>
          <li>5 Guest Credits</li>
          <li>Free Yoga Top</li>
        </ul>
      </v-col>
      <v-col cols="12" class="prize-container">
        <h2 class="prize-header"><img src="../assets/prize-icon.svg"> Prizes for all who Participate</h2>
        <p>Everyone who signs up will get something fun just for joining</p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="8" class="mt-15">
        <img style="min-width: 100%;" src="../assets/sun.svg">
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'DashboardPage',

  data() {
    return {
    }
  },

  computed: {
    validSignup() {
      if (this.challenge) {
        const deadline = DateTime.fromISO('2024-03-31T00:00-08:00')
        return (DateTime.fromISO(this.challenge.createdAt) < deadline)
      } else {
        return false
      }
    },
    classCount() {
      return this.$store.state.classCount
    },
    classCountText() {
      const i = this.classCount
      if (i <= 3) return 'Peace begins with a smile'
      if (i > 3 && i <= 7) return 'Such a strong start!'
      if (i > 7 && i <= 11) return 'Look at you go!'
      if (i > 11 && i <= 15) return 'Dedication never looked so good!'
      if (i > 15 && i <= 18) return 'You make it look easy'
      if (i > 18 && i <= 21) return 'Inhale peace, exhale love'
      if (i > 21 && i <= 25) return 'Transformation is a concious decision'
      if (i > 25 && i <= 28) return 'Dig deep, you\'ve got this!'
      if (i == 29) return 'Just one more to go!'
      return 'Congratulations!'
    },
    daysLeft() {
      const startDate = DateTime.fromISO('2024-03-01T00:00-08:00')
      const now = DateTime.now()
      const diffInDays = startDate.diff(now, 'days').toObject()

      return Math.floor(diffInDays.days)
    },
    isInProgress() {
      const startDate = DateTime.fromISO('2024-03-01T00:00-08:00').toMillis()
      const now = DateTime.now().toMillis()
      return (startDate < now)
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    prizesBgColor() {
      if (this.isAuthenticated) return 'background-color: #f8f4f0;'
      return 'background-color: #efe8e0;'
    },
    hasStarted() {
      return (this.startCountdown <= 0)
    },
    startCountdown() {
      const startDate = DateTime.fromISO('2023-01-09T00:00-08:00')
      const now = DateTime.now()
      const diffInDays = startDate.diff(now, 'days').toObject()

      return Math.ceil(diffInDays.days)
    },
    referenceUrl() {
      return `https://challenge.rayoga.com?ref=${this.user.id}`
    },
    radialValue() {
      const per = 100/30
      return this.classCount * per
    },
    user() {
      return this.$store.state.user
    }
  },

  watch: {
    canSignup(val) {
      if (val) this.checkboxError = null
    }
  },

  methods: {
    dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    },
    generateId (len) {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.referenceUrl)
    },
    logout() {
      window.localStorage.removeItem('vuex')
      window.location.href = 'https://challenge.rayoga.com?prompt=1'
    },
  },

  mounted() {
    if (this.isAuthenticated) this.$store.dispatch('getTotalClasses', this.$store.state.user.id)
    if (!this.isAuthenticated) this.$router.push({ name: 'Home' })

    this.challenge = this.generateId(40)
    this.$store.commit('setChallenge', this.challenge)
  }
}
</script>

<style lang="scss">
.dashboard span.v-chip__content {
  width: 100%;
  text-align: center;
  display: inline;
  padding-top: 16px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;

  span.countdown {
    color: #BCEFBA;
  }
}

.referenceLabel {
  color: #3E523C;
  font-weight: 800;
  margin-bottom: 4px;
  display: flex;
}

.progress-container {
  padding-top: 25px;
  padding-bottom: 25px;
  background: linear-gradient(180deg, rgba(31, 67, 93, 0.88) 0%, rgba(31, 67, 93, 0.80) 64.5%, rgba(31, 67, 93, 0.54) 100%), url('~@/assets/path12.png');
  background-size: cover;
  border-radius: 8px;
  color: #ffffff;

  .class-count {
    display: block;
    clear: both;
    color: #F8F4F0;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
  }

  .class-count-label {
    display: block;
    clear: both;
    color: #F8F4F0;
    font-weight: 100;
    font-size: 16px;
  }

  .quote {
    font-weight: 100;
    font-style: italic;
  }
}
</style>